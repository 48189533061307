import React from "react"

HeaderH2.defaultProps = {
  className: "",
  widthClass: "w-full"
};

export default function HeaderH2(props) {
return <>
    <h2 className={`${ props.className } ${ props.widthClass } py-2 lg:text-5xl text-3xl font-bold leading-tight text-center text-l5-secondary mx-auto`}>
      {props.headerText}
    </h2>
    <div className="w-full mb-4 mt-1">
        <div className="h-2 mx-auto bg-l6-secondary md:w-96 w-64 my-0 py-0" style={{"marginTop": "-15px"}}/>
    </div>
  </>
}
