import React from "react"
import HeaderH2 from "../headerH2";

const Feature_3_blocks_with_bg = (props) => {
  return (
    <section className="text-black body-font">
      <div className="container max-w-6xl px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <HeaderH2
            headerText={props.title}
            widthClass="md:w-4/6"
          />
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-lg h-full bg-l6-background p-8 flex-col">
              <div className="flex items-center mb-3">
                <div
                  className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-l6-secondary-variant text-white flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                       className="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"/>
                  </svg>
                </div>
                <h2 className="text-gray-900 text-lg title-font font-medium">{props.bp1Title}</h2>
              </div>
              <div className="flex-grow">
                <p className="leading-relaxed text-base">{props.bp1Text}</p>
                {props.bp1Link ?
                  <a className="mt-3 text-l6-secondary-variant inline-flex items-center" href={props.bp1Link}>Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                         strokeWidth="2"
                         className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"/>
                    </svg>
                  </a> : null}
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-lg h-full bg-l6-background p-8 flex-col">
              <div className="flex items-center mb-3">
                <div
                  className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-l6-secondary-variant text-white flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                       className="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
                    <circle cx="12" cy="7" r="4"/>
                  </svg>
                </div>
                <h2 className="text-gray-900 text-lg title-font font-medium">{props.bp2Title}</h2>
              </div>
              <div className="flex-grow">
                <p className="leading-relaxed text-base">{props.bp2Text}</p>
                {props.bp2Link ?
                  <a className="mt-3 text-l6-secondary-variant inline-flex items-center" href={props.bp2Link}>Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                         strokeWidth="2"
                         className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"/>
                    </svg>
                  </a> : null}
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-lg h-full bg-l6-background p-8 flex-col">
              <div className="flex items-center mb-3">
                <div
                  className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-l6-secondary-variant text-white flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                       className="w-5 h-5" viewBox="0 0 24 24">
                    <circle cx="6" cy="6" r="3"/>
                    <circle cx="6" cy="18" r="3"/>
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"/>
                  </svg>
                </div>
                <h2 className="text-gray-900 text-lg title-font font-medium">{props.bp3Title}</h2>
              </div>
              <div className="flex-grow">
                <p className="leading-relaxed text-base">{props.bp3Text}</p>
                {props.bp3Link ?
                  <a className="mt-3 text-l6-primary inline-flex items-center" href={props.bp3Link}>Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                         strokeWidth="2"
                         className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"/>
                    </svg>
                  </a> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Feature_3_blocks_with_bg;