import React from "react"
import Layout from "../components/layout"

import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import ServerApiHeader from "/src/assets/server-api/server-api.png";
import Feature3Blocks from "../components/tailblocks/feature_3_blocks_with_bg";
import ZapierIntegrationImg from "/src/assets/server-api/zapier-integration.png";
import ApiImg from "/src/assets/server-api/api.png";
import ZapierJsonPurchaseImg from "/src/assets/server-api/example-zapier-event-json.png";
import {Link} from "gatsby";

export default function UserManagement() {
  return (
    <>
      <GatsbySeo title="Subscription Server Api"
                 description={`Managing and viewing your users in-app purchase status should be easy and powerful. A single overview with all purchases and all users.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="product">
        <div className="pt-24 bg-l6-background ">
          <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mt-12">
            <div
              className="lg:mb-24 mb-12 md:pl-14 md:pr-14 mb-10 md:mb-0 md:pt-16 lg:w-4/6 w-full flex flex-col items-start">
              <h1
                className="font-extrabold leading-10 tracking-tight sm:leading-none md:w-4/5 md:text-6xl sm:text-5xl text-4xl">
                Connect your Server to our Subscription API
              </h1>

              <p className="my-5 text-lg md:w-4/5 md:text-xl md:mx-0 ">
                Connect your servers to LinkFive an check if a user purchased a subscription with
                your internal user id.
              </p>
            </div>

            <div className="w-full md:w-2/6 py-6 lg:ml-auto mx-auto lg:block hidden">

              <img alt="LinkFive User Management & Purchases Image"
                   title="LinkFive User Management & Purchases Image"
                   className=""
                   src={ServerApiHeader}/>
            </div>
          </div>
        </div>


        <Feature3Blocks title="One Server Api to check Subscription Purchases for all Stores"
                        bp1Text="Regardless of whether your users use iOS or Android, with our Api we link both worlds."
                        bp1Title="App Stores" bp1Link="/docs/"
                        bp2Text="You only need a Server Api Key to get Started. Just Login and go to the API Tab to generate a new Server Api Key"
                        bp2Title="API Keys"
                        bp3Text="We have documented our api with swagger so that you can start implementing it right away."
                        bp3Title="Swagger Specs"
                        bp3Link="https://app.swaggerhub.com/apis-docs/LinkFive/LinkFive-Server-Api/"
        />

        <section className="text-gray-600 body-font max-w-6xl mx-auto">
          <div className="container px-5 mx-auto">

            <div className="px-5 mx-auto flex flex-wrap">

              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={ApiImg}/>
              </div>

              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">Server Api </h3>
                  <p className="leading-relaxed text-lg text-base">When a user purchases a product in your app, we store the purchase in our database and update the entry if the user cancels it. You can then retrieve the purchase with your server and our Api-Key, regardless of which app store the purchase was made in.</p><br/>
                  <p className="leading-relaxed text-lg text-base">Our Server Api has several API-Endpoints. Please check out our <a href="https://app.swaggerhub.com/apis-docs/LinkFive/LinkFive-Server-Api/" target="_blank"> Swagger Documentation</a></p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="text-gray-600 body-font max-w-6xl mx-auto">
          <div className="container px-5 py-24 pt-36 mx-auto">
            <div className="flex flex-wrap w-full mb-16 flex-col items-center text-center">
              <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Integrations</h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto bg-l6-secondary md:w-96 w-64 my-0 py-0" style={{"marginTop": "-8px"}}/>
              </div>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">We offer a range of integrations. Here are a few:</p>
            </div>

            <div className="px-5 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={ZapierIntegrationImg}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">Connect LinkFive to Zapier for Purchase Events</h3>
                  <p className="leading-relaxed text-lg text-base">Whenever a user Purchases an In-App product in your App, you can use Zapier to get real-time purchase events. </p><br/>
                  <p className="leading-relaxed text-lg text-base">With Zapier, you can connect these events to Slack, Google Teams, Google Sheet or thousands of other apps.</p>
                  <p className="leading-relaxed text-lg text-base "><a
                    href="/docs/integrations/zapier-integration/"
                    className="secondary-action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                  >
                    READ our Zapier Docs
                  </a></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="text-gray-600 body-font max-w-6xl mx-auto">
          <div className="container px-5 pb-24 mx-auto">

            <div className="px-5 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={ZapierJsonPurchaseImg}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">Example Purchase Events</h3>
                  <p className="leading-relaxed text-lg text-base">The <b>"NEW_PLAN"</b> purchase event will be triggered whenever a user of your app purchases a new iOS or Android subscription in your Flutter app. The event-data will then be delivered to your integration like Zapier.</p><br/>
                  <p className="leading-relaxed text-lg text-base">You can forward the event to your Company's chat system such as Slack, Google Chat, Discord or even Google Sheets.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    </>
  )
}
